import React from "react";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { gql, useMutation } from "@apollo/client";
import { Button, ButtonBase, Grid, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import Loading from "./Loading";

const UPDATE_ORDEM_RECOMENDADOS = gql`
  mutation changeRecommendedEpisodeOrder($id: Int!, $pos: Int!) {
    changeRecommendedEpisodeOrder(id: $id, position: $pos) {
      id
      episode {
        title
        thumbnail
        channel {
          name
        }
      }
    }
  }
`;

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const DELETE_EPS_RECOMENDADOS = gql`
  mutation deleteRecommendedEpisode($id: Int!) {
    deleteRecommendedEpisode(id: $id) {
      id
      episode {
        title
        thumbnail
        channel {
          name
        }
      }
    }
  }
`;

const SortableItem = sortableElement(({ value: eps, onDelete }) => {
  const [deleteEps, { loading: loadingDelete }] = useMutation(
    DELETE_EPS_RECOMENDADOS,
    {
      onError: (error) => {
        toast.error("ERROR: " + error.message);
      },
      onCompleted: (data) => {
        onDelete();
      },
      fetchPolicy: "network-only",
    }
  );

  const deleteEpsRecomendados = () => {
    deleteEps({
      variables: {
        id: eps.id,
      },
    });
  };

  return (
    <div>
      <Paper
        key={eps.id}
        sx={{
          p: 2,
          marginBottom: 2,
          maxWidth: 500,
          flexGrow: 1,
          cursor: "grab",
          backgroundColor: (theme) =>
            theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        }}
      >
        <Grid container spacing={2}>
          <Grid item>
            <ButtonBase sx={{ width: 128, height: 128 }}>
              <Img alt="complex" src={eps?.episode?.thumbnail} />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1" component="div">
                  {eps.episode.channel.name}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {eps?.episode?.title}
                </Typography>
              </Grid>
              <Grid item>
                <Button variant="outlined" disabled={loadingDelete} onClick={deleteEpsRecomendados}>
                  Remover {loadingDelete && <Loading />}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
});

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

export default function DraggableEpisode(props) {
  const [items, setItems] = React.useState();
  const [updateOrder, {loading}] = useMutation(UPDATE_ORDEM_RECOMENDADOS, {
    onError: (error) => {
      toast.error("ERROR: " + error.message);
    },
    onCompleted: () => {
      toast.success("Episodio ordenado com sucesso.");
    },
  });

  const onSortEnd = ({oldIndex, newIndex, nodes}) => {
    const epsId = items[oldIndex].id;
    setItems((prevState) => {
      return arrayMoveImmutable(prevState, oldIndex, newIndex);
    });
    updateOrder({
      variables: {
        id: epsId,
        pos: newIndex + 1,
      }
    })
  };

  React.useEffect(() => {
    setItems(props.items);
  }, [props.items]);

  return (
    <SortableContainer onSortEnd={onSortEnd}>
      {loading && <Loading/>}
      {items?.map((value, index) => (
        <SortableItem
          key={`item-${index}`}
          onDelete={props.onDelete}
          index={index}
          value={value}
        />
      ))}
    </SortableContainer>
  );
}
