import * as React from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useLazyQuery } from "@apollo/client";
import { toast, ToastContainer } from "react-toastify";
import Loading from "../components/Loading";
import { LinkedWebsite } from "../components/LinkedWebsite";
import { GET_LINKED_WEBSITE, RESGATA_CHANNEL } from "./BrazilJournal";

const CODENAME = "cartacapital";

export default function CartaCapital() {
  const [cartaCapital, setCarta] = React.useState("");
  const [dataSearch, setDataSearch] = React.useState("");

  const [getCarta, { data: dataCarta }] =
    useLazyQuery(GET_LINKED_WEBSITE, {
      onError: (error) => {
        toast.error("ERROR: " + error.message);
      },
      fetchPolicy: "network-only",
    });

  const onChange = (event) => {
    setCarta(event.target.value);
  };
  const [searchChannel, { loading: loadingSearch }] =
    useLazyQuery(RESGATA_CHANNEL, {
      onError: (error) => {
        toast.error("ERROR: " + error.message);
      },
      onCompleted: (data) => {
        if (data?.channels?.data.length === 0) {
          toast.warning("Canal nao encontrado por codenome.");
        }
        setDataSearch(data);
      },
      fetchPolicy: "network-only",
    });

  const resgata = () => {
    if (cartaCapital !== '') {
      searchChannel({
        variables: {
          codename: cartaCapital,
        },
      });
    }
  };

  const onRemoved = () => {
    getCarta({
      variables: {
        codename: CODENAME,
      },
    });
    toast.success("Canal removido com sucesso");
  };

  const onUpdate = () => {
    toast.success("Canal atualizado com sucesso");
  };

  const onAddChannel = () => {
    getCarta({
      variables: {
        codename: CODENAME,
      },
    });
    setDataSearch(null);
    toast.success("Canal adicionado com sucesso");
  };

  React.useEffect(() => {
    getCarta({
      variables: {
        codename: CODENAME,
      },
    });
  }, [getCarta]);

  return (
    <>
      <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
        Digite o codenome do canal que deseja buscar:
      </Typography>

      <TextField
        id="outlined-basic"
        label="Codenome"
        variant="outlined"
        onChange={onChange}
      />
      <Button variant="outlined" sx={{ ml: 2, mt: 1 }} onClick={resgata}>
        Buscar {loadingSearch && <Loading />}
      </Button>

      {dataSearch?.channels?.data?.map((addChannel) => {
        return (
          <>
            <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
              Canal Adicionar:
            </Typography>
            <LinkedWebsite
              key={addChannel.id}
              channel={{ channel: addChannel }}
              linkedWebsiteId={dataCarta?.linkedWebsite?.id}
              onRemoved={onRemoved}
              onUpdate={onAddChannel}
              canRemove={false}
            />
          </>
        );
      })}

      <Grid item xs={12} md={6}>
        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
          Canais Carta Capital:
        </Typography>
        {dataCarta?.linkedWebsite?.channels_with_configs?.data?.map(
          (channel) => {
            return (
              <LinkedWebsite
                key={channel?.channel?.id}
                channel={channel}
                linkedWebsiteId={dataCarta?.linkedWebsite?.id}
                onRemoved={onRemoved}
                onUpdate={onUpdate}
                canRemove={true}
              />
            );
          }
        )}
        <ToastContainer />
      </Grid>
    </>
  );
}

//