import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Main from './pages/Main';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  HttpLink
} from "@apollo/client";
import './App.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { BrowserRouter } from 'react-router-dom';
import { createUploadLink } from 'apollo-upload-client';
import { buildAxiosFetch } from '@lifeomic/axios-fetch';
import axios from "axios";

const axiosClient = axios.create();


const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
  fetch: buildAxiosFetch(axiosClient, (config, input, init) => ({
    ...config,
    onUploadProgress: init.onUploadProgress,
  })),
});


const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  let storedUser = localStorage.getItem('user');
  if(storedUser){
    storedUser = JSON.parse(storedUser);
  }
  
  const token = storedUser?.login?.access_token;
  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      Authorization: token ? `Bearer ${token}` : ''
    }
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const client = new ApolloClient({
  link: authLink.concat(httpLink), // Chain it with the HttpLink
  cache: new InMemoryCache()
});


const root = ReactDOM.createRoot(document.getElementById('root'));


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

root.render(
  <ApolloProvider client={client}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
    <BrowserRouter>
      <Main/>
      </BrowserRouter>
    </LocalizationProvider>
  </ApolloProvider>
);