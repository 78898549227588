import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';

export default function BlockingBackdrop(props) {
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open])

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        {props.children}
      </Backdrop>
    </div>
  );
}
