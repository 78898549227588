import { styled } from "@mui/material/styles";
import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import React from "react";
import { Box } from "@mui/system";
import { gql, useMutation } from "@apollo/client";
import { toast, ToastContainer} from "react-toastify";
import ProgressBar from "./ProgressBar";

const Input = styled("input")({
  display: "none",
});

const UPDATE_EPS_PREMIUM = gql`
  mutation editEpisode(
    $episode_id: Int!
    $title: String
    $description: String
    $publishDate: Int
    $restricted: Boolean
    $rating: String
    $original: Boolean
    $premium: Boolean
    $thumbnail: Upload
    $content_video: Upload
  ) {
    editEpisode(
      id: $episode_id
      title: $title
      thumbnail: $thumbnail
      description: $description
      publish_date: $publishDate
      restricted: $restricted
      rating: $rating
      original: $original
      premium: $premium
      content_video: $content_video
    ) {
      id
      title
      thumbnail
      description
      publishDate
      restricted
      rating
      original
      premium
    }
  }
`;

const DELETE_EPS_PREMIUM = gql`
  mutation DeleteEpisode($id: Int!) {
    deleteEpisode(id: $id)
  }
`;

export default function EpisodeForm(props) {
  const [title, setTitle] = React.useState(props?.episode?.title);
  const [progress, setProgress] = React.useState(0);
  const [description, setDescription] = React.useState(
    props?.episode?.description
  );
  const [dtPublication, setDtPublication] = React.useState(
    new Date(props?.episode?.publishDate * 1000)
  );
  const [classification, setClassification] = React.useState(
    props?.episode?.rating || ""
  );
  const [typeOriginal, setTypeOriginal] = React.useState(
    props?.episode?.original
  );
  const [typePremium, setTypePremium] = React.useState(props?.episode?.premium);
  const [thumbnailUpload, setThumbnailUpload] = React.useState();
  const [thumbnail, setThumbnail] = React.useState(props?.episode?.thumbnail);
  const [videoUpload, setVideoUpload] = React.useState();

  const [updateEps, { loading: loadingUpdate }] = useMutation(
    UPDATE_EPS_PREMIUM,
    {
      onError: (error) => {
        let message = "";
        setProgress(0);
        for (const validationError of error.graphQLErrors) {
          message += validationError.extensions.validation.description[0];
        }
        toast.error(message);
        
      },
      onCompleted: (data) => {
        toast.success("Episodio Atualizado com Sucesso.");
        setProgress(0);
      },
      fetchPolicy: "network-only",
    }
  );




  const [deleteEps, { loading: loadingDelete }] = useMutation(
    DELETE_EPS_PREMIUM,
    {
      onError: (error) => {
        toast.error("ERROR: " + error.message);
      },
      onCompleted: (data) => {
        props.onDelete();
      },
      fetchPolicy: "network-only",
    }
  );

  const onChangeTitle = (event) => {
    setTitle(event.target.value);
  };

  const onChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleDtPublication = (newValue) => {
    setDtPublication(newValue);
  };

  const handleClassification = (event) => {
    setClassification(event.target.value);
  };

  const handleTypeOriginal = (event) => {
    setTypeOriginal(event.target.checked);
  };
  const handleTypePremium = (event) => {
    setTypePremium(event.target.checked);
  };

  const onChangeVideolUpload = (event) => {
    setVideoUpload(event.target.files[0]);
  };

  const onChangeThumbnailUpload = (event) => {
    setThumbnailUpload(event.target.files[0]);
    setThumbnail(URL.createObjectURL(event.target.files[0]));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    updateData();
  };

  const updateData = (url) => {
    updateEps({
      context: {
        fetchOptions: {
          onUploadProgress: (progress) => {
            setProgress(
              ((progress.loaded * 100) / progress.total)
                .toString()
                .split(".", 2)[0]
            );
            console.log(progress);
          },
        },
      },
      variables: {
        episode_id: props.episode.id,
        title: title,
        thumbnail: thumbnailUpload,
        description: description,
        publish_date: dtPublication,
        // restricted: episode.restricted,
        rating: classification,
        original: typeOriginal,
        premium: typePremium,
        content_video: videoUpload,
      },
    });
  };

  const deleteEpisode = () => {
    deleteEps({
      variables: {
        id: props?.episode?.id,
      },
    });
  };

  return (
    <Card variant="outlined" sx={{ marginBottom: "20px" }}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <Grid container>
          <Grid Item sx={{ margin: 2 }}>
            <img
              alt=""
              src={thumbnail}
              style={{ width: "200px", height: "200px" }}
            />
            <div>
              <label htmlFor={`thumbnail-${props?.episode?.id}`}>
                <Input
                  name={`thumbnail-${props?.episode?.id}`}
                  accept="image/*"
                  id={`thumbnail-${props?.episode?.id}`}
                  type="file"
                  onChange={onChangeThumbnailUpload}
                />

                <Button
                  sx={{ display: "inline-flex" }}
                  variant="contained"
                  component="span"
                >
                  Upload Thumbnail
                </Button>
              </label>
              <div>{thumbnailUpload?.name}</div>
            </div>
          </Grid>
          <Grid Item sx={{ margin: "20px" }} xs={9}>
            <h3>Titulo</h3>
            <TextField
              fullWidth
              id="outlined-basic"
              label=""
              variant="outlined"
              onChange={onChangeTitle}
              value={title}
              size="small"
              sx={{ width: 500 }}
            />

            <h3>Descricao</h3>
            <TextareaAutosize
              style={{ width: 500 }}
              aria-label="Descricao"
              placeholder="Descricao"
              value={description}
              onChange={onChangeDescription}
            />
            <h3>Trocar Data de Publicacao</h3>
            <DateTimePicker
              label="Data Publicacao"
              inputFormat="dd/MM/yyyy HH:mm"
              mask="__/__/____ __:__"
              value={dtPublication}
              onChange={handleDtPublication}
              renderInput={(params) => (
                <TextField sx={{ width: 500 }} {...params} />
              )}
            />
            <h3>Classificacao</h3>
            <FormControl sx={{ m: 0, minWidth: 120 }} size="small">
              <InputLabel shrink id="demo-select-small">
                Classificacao
              </InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={classification}
                displayEmpty
                onChange={handleClassification}
                input={<OutlinedInput notched label="Classificacao" />}
                sx={{ width: 500 }}
              >
                <MenuItem value={""}>Sem classificacao</MenuItem>
                <MenuItem value={"L"}>Livre</MenuItem>
                <MenuItem value={"+18"}>+18</MenuItem>
              </Select>

              <h3>Video .mp4:</h3>
              <Grid container>
                <Grid item>
                  <div>
                    <label htmlFor={`episode--${props?.episode?.id}`}>
                      <Input
                        name={`episode--${props?.episode?.id}`}
                        accept="video/*"
                        id={`episode--${props?.episode?.id}`}
                        type="file"
                        onChange={onChangeVideolUpload}
                      />

                      <Button
                        sx={{ display: "inline-flex", marginRight: 1 }}
                        variant="contained"
                        component="span"
                      >
                        Escolher Video
                      </Button>
                    </label>
                  </div>
                </Grid>
                <Grid item></Grid>
              </Grid>
              <div>{videoUpload?.name}</div>
            </FormControl>
            <h3>Tipo</h3>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    value={typePremium}
                    onChange={handleTypePremium}
                  />
                }
                label="Premium"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={typeOriginal}
                    onChange={handleTypeOriginal}
                  />
                }
                label="Original"
              />
            </FormGroup>
            <Button
              variant="outlined"
              type="submit"
              disabled={loadingUpdate}
              sx={{ marginRight: "5px" }}
            >
              Atualizar
              {loadingUpdate && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
            <Button
              variant="outlined"
              disabled={loadingDelete}
              onClick={deleteEpisode}
            >
              Deletar
              {loadingDelete && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
            <Button sx={{ cursor: "default" }}>
              {progress > 0 && (
                <ProgressBar
                  progress={progress}
                  style={{ width: "100px", marginLeft: "10px" }}
                />
              )}
            </Button>
          </Grid>
        </Grid>
        <ToastContainer />
      </Box>
    </Card>
  );
}
