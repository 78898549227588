import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import * as React from "react";
import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import Loading from "./Loading";
import { getUnixTime } from "date-fns";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Input = styled("input")({
  display: "none",
});

const optionAction = [
  { title: "Nenhuma", value: "none" },
  { title: "Ir para CANAL", value: "channel" },
  { title: "Audio", value: "audio" },
  { title: "Video", value: "video" },
];

const optionType = [
  { title: "HOME - WEB", value: "9b03af46-cff0-462e-9ca1-1994765c69a9" },
  { title: "HOME - APP", value: "8362c7a5-20aa-49f7-b8df-7f35a3410bb0" },
  { title: "HOME LOGADO - WEB", value: "617b5eaf-9a61-4801-a297-64e6d1cc9aa0" },
  { title: "HOME LOGADO - APP", value: "27ee76b2-99cc-4e4b-b1ad-5ffd00182b87" },
];

const optionPosition = [
  { title: "NAO FIXO", value: 0 },
  { title: "1", value: 1 },
  { title: "2", value: 2 },
  { title: "3", value: 3 },
  { title: "4", value: 4 },
  { title: "5", value: 5 },
];

const CREATE_BANNERS = gql`
  mutation createSlide(
    $banner_id: String!
    $cover: String
    $title: String!
    $description: String!
    $action: String!
    $arguments: String
    $visibility: String
    $position: Int
    $initial_date: Int
    $final_date: Int
    $keywords: String
  ) {
    createSlide(
      banner_id: $banner_id
      title: $title
      cover_by_url: $cover
      description: $description
      visibility: $visibility
      action: $action
      arguments: $arguments
      position: $position
      initial_date: $initial_date
      final_date: $final_date
      keywords: $keywords
    ) {
      id
    }
  }
`;

export default function NewBanner(props) {
  const [bannerTitle, setBannerTitle] = React.useState('');
  const [upload, setUpload] = React.useState();
  const [dateInicio, setDateInicio] = React.useState(new Date());
  const [dateFim, setDateFim] = React.useState(new Date());
  const [bannerDescription, setBannerDescription] = React.useState('');
  const [bannerCodename, setBannerCodename] = React.useState('');
  const [open, setOpen] = React.useState(true);
  const [action, setAction] = React.useState(null);
  const [type, setType] = React.useState(null);
  const [position, setPosition] = React.useState(null);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => {
    props.onClose();
    setOpen(false);
  };
  const handleDateInicio = (newValue) => {
    setDateInicio(newValue);
  };
  const handleDateFim = (newValue) => {
    setDateFim(newValue);
  };

  const onChangeBannerTitle = (event) => {
    setBannerTitle(event.target.value);
  };
  const onChangeBannerCodename = (event) => {
    setBannerCodename(event.target.value);
  };
  const onChangeBannerDescription = (event) => {
    setBannerDescription(event.target.value);
  };
  const onChangeUpload = (event) => {
    setUpload(event.target.files[0]);
  };
  const handleActionChange = (event, newValue) => {
    setAction(newValue);
  };
  const handleTypeChange = (event, newValue) => {
    setType(newValue);
  };
  const handlePositionChange = (event, newValue) => {
    setPosition(newValue);
  };

  const defaultProps = {
    options: optionAction,
    getOptionLabel: (option) => option.title,
  };

  const defaultPropsPosition = {
    options: optionPosition,
    getOptionLabel: (option) => option.title,
  };

  const defaultPropsType = {
    options: optionType,
    getOptionLabel: (option) => option.title,
  };

  const [createBanner, { loading: loadingBanner }] = useMutation(
    CREATE_BANNERS,
    {
      onError: (error) => {
        toast.error("ERROR: " + error.message);
      },
      onCompleted: (data) => {
       props.onCreate();
       handleClose();
      },
      fetchPolicy: "network-only",
    }
  );

  const handleSubmit = async (event) => {
    event.preventDefault();

    let url = '';
    if (upload) {
      const formData = new FormData();
      // Update the formData object
      formData.append("file", upload, upload.name);

      // Request made to the backend api
      // Send formData object
      const response = await fetch(
        "https://adm.frank.olapodcasts.com:8080/admin/uploadFile.php",
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      url = data.url;
    }

    createBanner({
      variables: {
        banner_id: type.value,
        title: bannerTitle,
        cover_by_url: url,
        description: bannerDescription,
        action: action.value,
        position: position.value,
        initial_date: getUnixTime(dateInicio),
        final_date: getUnixTime(dateFim),
        arguments: bannerCodename,
        visibility: 'all',
      },
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        ADICIONAR BANNER
        <Divider />
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <TextField
            id="standard-search"
            label="Codename Canal"
            type="search"
            variant="standard"
            value={bannerCodename}
            onChange={onChangeBannerCodename}
          />
          <TextField
            id="standard-search"
            label="Titulo"
            type="search"
            variant="standard"
            value={bannerTitle}
            onChange={onChangeBannerTitle}
          />
          <TextField
            id="standard-search"
            label="Descricao"
            type="search"
            variant="standard"
            value={bannerDescription}
            onChange={onChangeBannerDescription}
          />
          <Autocomplete
            {...defaultProps}
            sx={{ display: "inline-flex" }}
            id="disable-clearable"
            disableClearable
            onChange={handleActionChange}
            value={action}
            renderInput={(params) => (
              <TextField {...params} label="Acao" variant="standard" />
            )}
          />
          <Autocomplete
            {...defaultPropsPosition}
            id="disable-clearable"
            sx={{ display: "inline-flex" }}
            disableClearable
            onChange={handlePositionChange}
            value={position}
            renderInput={(params) => (
              <TextField {...params} label="Posicao" variant="standard" />
            )}
          />
          <Autocomplete
            {...defaultPropsType}
            id="disable-clearable"
            disableClearable
            sx={{ display: "inline-flex" }}
            onChange={handleTypeChange}
            value={type}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tipo de Banner"
                variant="standard"
              />
            )}
          />
          <DateTimePicker
            label="Data de Inicio"
            inputFormat="dd/MM/yyyy HH:mm"
            mask="__/__/____ __:__"
            value={dateInicio}
            onChange={handleDateInicio}
            renderInput={(params) => <TextField {...params} />}
          />
          <DateTimePicker
            label="Data de fim"
            inputFormat="dd/MM/yyyy HH:mm"
            mask="__/__/____ __:__"
            value={dateFim}
            onChange={handleDateFim}
            renderInput={(params) => <TextField {...params} />}
          />
          <Grid item>
            <label htmlFor="contained-button-file">
              <Input
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                onChange={onChangeUpload}
              />

              <Button
                sx={{ display: "inline-flex", marginRight: 5 }}
                variant="contained"
                component="span"
                
              >
                Upload Banner
              </Button>
            </label>
            <Button 
            disabled={loadingBanner} 
            onClick={handleSubmit} 
            variant="outlined">
              ADICIONAR BANNER
              {loadingBanner && <Loading/>}
            </Button>
          </Grid>
          {upload?.name}
        </Box>
      </Box>
    </Modal>
  );
}
