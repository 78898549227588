import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Grid, Typography } from "@mui/material";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ButtonBase from "@mui/material/ButtonBase";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { toast, ToastContainer } from "react-toastify";
import Loading from "../components/Loading";
import DraggableEpisode from "../components/DraggableEpisode";

const GET_EPS_RECOMENDADOS = gql`
  query recommendedEpisodesAdmin {
    recommendedEpisodesAdmin {
      id
      episode {
        title
        thumbnail
        channel {
          id
          name
        }
      }
    }
  }
`;

const GET_EPS = gql`
  query episodes($filter: String) {
    episodes(filter: $filter, page: 1, limit: 5) {
      data {
        id
        title
        thumbnail
        channel {
          name
        }
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
  }
`;

const ADD_EPS_RECOMENDADOS = gql`
  mutation addRecommendedEpisode($episode_id: Int!) {
    addRecommendedEpisode(episode_id: $episode_id) {
      id
      episode {
        title
        thumbnail
        channel {
          name
        }
      }
    }
  }
`;

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

export default function EpsRecomendados() {
  const [epsSearch, setEpsSearch] = React.useState();
  const [epsId, setEpsId] = React.useState();

  const [getEpsRecomendados, { data }] = useLazyQuery(GET_EPS_RECOMENDADOS, {
    onError: (error) => {
      toast.error("ERROR: " + error.message);
    },
    fetchPolicy: "network-only",
  });

  const [getEps, { loading: loadingEps, data: dataEps }] = useLazyQuery(
    GET_EPS,
    {
      onError: (error) => {
        toast.error("ERROR: " + error.message);
      },
    }
  );

  const [addRecommendedEpisode, { loading: loadingAddRecommended }] =
    useMutation(ADD_EPS_RECOMENDADOS, {
      onError: (error) => {
        toast.error("ERROR: " + error.message);
      },
      onCompleted: () => {
        toast.success("Episodio adicionado com sucesso.");
        getEpsRecomendados();
      },
    });

  const searchEps = (event) => {
    event.preventDefault();

    getEps({
      variables: {
        filter: epsSearch,
      },
    });
  };

  const onChangeEps = (event) => {
    setEpsSearch(event.target.value);
  };

  const onDelete = () => {
    getEpsRecomendados();
    toast.success("Episodio removido com sucesso.")
  };

  const addToRecommended = (id) => {
    setEpsId(id);
    addRecommendedEpisode({
      variables: {
        episode_id: id,
      },
    });
  };

  React.useEffect(() => {
    getEpsRecomendados();
  }, [getEpsRecomendados]);

  return (
    <Box
      onSubmit={searchEps}
      component="form"
      sx={{
        "& > :not(style)": { m: 1, width: "25" },
      }}
      noValidate
      autoComplete="off"
    >
      <Typography
        sx={{ mt: 4, mb: 2, fontSize: 18, fontWeight: "bolder" }}
        component="div"
      >
        Digite o nome do episódio:
      </Typography>

      <Grid container spacing={2}>
        <Grid item>
          <TextField
            onChange={onChangeEps}
            id="outlined-basic"
            label="Episódio"
            sx={{ width: 300 }}
            variant="outlined"
          />
        </Grid>
        <Grid item>
          <Button
            disabled={loadingEps}
            onClick={searchEps}
            variant="outlined"
            sx={{ marginTop: 1 }}
          >
            Buscar
            {loadingEps && <Loading />}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={6}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Episodios Recomendados:
          </Typography>
          <b>Arraste para ordenar:</b>
          <DraggableEpisode
            items={data?.recommendedEpisodesAdmin}
            onDelete={onDelete}
          />
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <Typography sx={{ mt: 4, mb: 5 }} variant="h6" component="div">
            Lista de Episodios:
          </Typography>
          {dataEps?.episodes?.data?.map((eps) => {
            return (
              <Paper
                key={eps.id}
                sx={{
                  p: 2,
                  marginBottom: 2,
                  maxWidth: 500,
                  flexGrow: 1,
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item>
                    <ButtonBase sx={{ width: 128, height: 128 }}>
                      <Img alt="complex" src={eps?.thumbnail} />
                    </ButtonBase>
                  </Grid>
                  <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                      <Grid item xs>
                        <Typography
                          gutterBottom
                          variant="subtitle1"
                          component="div"
                        >
                          {eps?.channel?.name}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {eps?.title}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          disabled={loadingAddRecommended}
                          onClick={() => addToRecommended(eps.id)}
                        >
                          Adicionar {loadingAddRecommended && epsId === eps.id && <Loading />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            );
          })}
        </Grid>
        <ToastContainer/>
      </Grid>
    </Box>
  );
}
