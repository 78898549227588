import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Grid, Typography } from "@mui/material";
import { Button } from "@mui/material";
import { gql, useLazyQuery } from "@apollo/client";
import { toast, ToastContainer } from "react-toastify";
import Loading from "../components/Loading";
import Channel from "../components/Channel";

const GET_CANAIS_RECOMENDADOS = gql`
  query recommendedChannels {
    recommendedChannels(limit: 100) {
      data {
        priority
        channel {
          id
          name
          codename
          thumbnail(width: 200, height: 200)
          cover(width: 400, height: 100)
          main_category {
            id
          }
        }
      }
    }
  }
`;

const GET_CANAIS = gql`
  query channels($filter: String) {
    channels(filter: $filter, page: 1, limit: 5) {
      data {
        id
        name
        codename
        thumbnail(width: 200, height: 200)
        cover(width: 400, height: 100)
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
  }
`;

export default function CanaisRecomendados() {
  const [search, setSearch] = React.useState();

  const [getRecomendados, { data }] = useLazyQuery(GET_CANAIS_RECOMENDADOS, {
    onError: (error) => {
      toast.error("ERROR: " + error.message);
    },
    fetchPolicy: "network-only",
  });

  const [getCanais, { loading, data: dataCanais }] = useLazyQuery(GET_CANAIS, {
    onError: (error) => {
      toast.error("ERROR: " + error.message);
    },
  });


  const searchCanais = (event) => {
    event.preventDefault();

    getCanais({
      variables: {
        filter: search,
      },
    });
  };
  const onChange = (event) => {
    setSearch(event.target.value);
  };

  const onDelete = () => {
    toast.success("Canal removido com sucesso");
    getRecomendados();
  };

  const onUpdate = () => {
    toast.success("Canal atualizado com sucesso");
    getRecomendados();
  }
  
  const onAdd = () => {
    toast.success("Canal adicionado com sucesso");
    getRecomendados();
    getCanais({
      variables: {
        filter: search,
      },
    });
  }

  React.useEffect(() => {
    getRecomendados();
  }, [getRecomendados]);

  return (
    <Box
      onSubmit={searchCanais}
      component="form"
      sx={{
        "& > :not(style)": { m: 1, width: "25" },
      }}
      noValidate
      autoComplete="off"
    >
      <Typography
        sx={{ mt: 4, mb: 2, fontSize: 18, fontWeight: "bolder" }}
        component="div"
      >
        Digite o nome do canal:
      </Typography>

      <Grid container spacing={2}>
        <Grid item>
          <TextField
            onChange={onChange}
            id="outlined-basic"
            label="Canal"
            sx={{ width: 300 }}
            variant="outlined"
          />
        </Grid>
        <Grid item>
          <Button
            disabled={loading}
            onClick={searchCanais}
            variant="outlined"
            type="submit"
            sx={{ marginTop: 1}}
          >
            Buscar
            {loading && <Loading />}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={6}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Canais Recomendados:
          </Typography>
          {data?.recommendedChannels?.data.map((channel) => {
            return (
              <Channel
                key={channel?.channel?.id}
                priority={channel?.priority}
                channel={channel?.channel}
                onDelete={onDelete}
                onUpdate={onUpdate}
                hasAdd={false}
              />
            );
          })}
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Lista de Canais:
          </Typography>
          {dataCanais?.channels?.data?.map((channel) => {
            return (
              <Channel
                recommendedChannels={data?.recommendedChannels?.data}
                key={channel?.id}
                priority={0}
                channel={channel}
                onAdd={onAdd}
                hasAdd={true}
              />
            );
          })}
        </Grid>
      </Grid>
      <ToastContainer/>
    </Box>
  );
}
