import { gql, useMutation } from "@apollo/client";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { format } from "date-fns";
import { toast } from "react-toastify";
import Loading from "./Loading";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const DELETE_BANNERS = gql`
    mutation DeleteSlide($id: String!) {
        deleteSlide(id: $id)
    }
`;

export function Banner(props) {
  const [deleteSlide, { loading: loadingDelete }] = useMutation(
    DELETE_BANNERS,
    {
      onError: (error) => {
        toast.error("ERROR: " + error.message);
      },
      onCompleted: (data) => {
        props.onDelete();
      },
      fetchPolicy: "network-only",
    }
  );
  const onDelete = () => {
    deleteSlide({
      variables: {
        id: add.id,
      },
    });
  };
  const add = props.slide;
  return (
    <Paper
      sx={{
        p: 2,
        marginBottom: 2,
        maxWidth: 500,
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === "dark" ? "#1A2027" : "#fff",
      }}
    >
      <Grid container spacing={2}>
        <Grid item>
          
            <Img sx={{ marginLeft: 4 }} alt="complex" src={add?.cover} />
        
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography gutterBottom variant="body2" component="div">
                Inicio em:{" "}
                {format(
                  new Date(add.initialDate * 1000),
                  "dd/MM/yyyy - HH:mm:ss"
                )}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Termino em:{" "}
                {format(
                  new Date(add.publishDate * 1000),
                  "dd/MM/yyyy - HH:mm:ss"
                )}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                sx={{ cursor: "pointer" }}
                variant="outlined"
                onClick={onDelete}
                disabled={loadingDelete}
              >
                Remover {loadingDelete && <Loading />}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
