import React from "react";
import { gql, useMutation } from "@apollo/client";
import {
  Button,
  ButtonBase,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import Loading from "./Loading";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const DELETE_CANAIS_RECOMENDADOS = gql`
  mutation deleteRecommendedChannel($id: Int!) {
    deleteRecommendedChannel(channel_id: $id)
  }
`;

const UPDATE_CANAIS = gql`
  mutation changeRecommendedChannelPriority(
    $channel_id: Int!
    $priority: Int!
  ) {
    changeRecommendedChannelPriority(
      channel_id: $channel_id
      priority: $priority
    ) {
      priority
      channel {
        id
        name
        codename
        thumbnail(width: 200, height: 200)
        cover(width: 400, height: 100)
      }
    }
  }
`;

const ADD_RECOMMENDED_CHANNEL = gql`
  mutation addRecommendedChannel ($channel_id: Int!, $priority: Int!){
    addRecommendedChannel(channel_id: $channel_id, priority: $priority){
        priority
        channel {
            id
            name
            codename
            thumbnail(width: 200, height: 200)
            cover(width: 400, height: 100)
        }
    }
  }
`;

export default function Channel(props) {
  const canal = props.channel;
  const recommendedChannels = props.recommendedChannels;
  const [priority, setPriority] = React.useState('');
  const [deleteCanal, { loading: loadingDelete }] = useMutation(
    DELETE_CANAIS_RECOMENDADOS,
    {
      onError: (error) => {
        toast.error("ERROR: " + error.message);
      },
      onCompleted: (data) => {
        props.onDelete();
      },
      fetchPolicy: "network-only",
    }
  );
  const deleteCanalRecomendado = () => {
    deleteCanal({
      variables: {
        id: canal.id,
      },
    });
  };

  const [updateCanal, { loading: loadingUpdate }] =
    useMutation(UPDATE_CANAIS, {
      onError: (error) => {
        toast.error("ERROR: " + error.message);
      },
      onCompleted: (data) => {
        props.onUpdate();
      },
      fetchPolicy: "network-only",
    });

  const [addCanal, { loading: loadingAdd }] =
    useMutation(ADD_RECOMMENDED_CHANNEL, {
      onError: (error) => {
        toast.error("ERROR: " + error.message);
      },
      onCompleted: (data) => {
        props.onAdd();
      },
      fetchPolicy: "network-only",
    });

  const onChangePriority = (event) => {
    setPriority(event.target.value);
  };

  const updateCanalRecomendado = () => {
    updateCanal({
      variables: {
        channel_id: canal?.id,
        priority: priority,
      },
    });
  };

  const addCanalRecomendado = () => {
    addCanal({
      variables: {
        channel_id: canal?.id,
        priority: priority,
      }
    })
  }

  React.useEffect(() => {
    setPriority(props?.priority);
  }, [props?.priority]);

  // Check if Channel is already added to recommended
  if (recommendedChannels?.find((v) => canal.id === v.channel.id)) {
    return null;
  }

  return (
    <div>
      <Paper
        key={canal?.id}
        sx={{
          p: 2,
          marginBottom: 2,
          maxWidth: 500,
          flexGrow: 1,
          backgroundColor: (theme) =>
            theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        }}
      >
        <Grid container spacing={2}>
          <Grid item>
            <ButtonBase sx={{ width: 128, height: 128 }}>
              <Img alt="complex" src={canal?.thumbnail} />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1" component="div">
                  {canal?.name}
                </Typography>
              </Grid>
              <Typography sx={{ ml: 2 }} variant="body2" gutterBottom>
                Prioridade:
                <input
                  type="number"
                  style={{ width: "50px", marginLeft: 10 }}
                  onChange={onChangePriority}
                  value={priority}
                  id="quantity"
                  name="quantity"
                  min="1"
                  max="200"
                />
              </Typography>

              <Grid item>
                {!props.hasAdd && 
                  <>
                    <Button
                      sx={{ ml: 2, mr: 2 }}
                      variant="outlined"
                      disabled={loadingUpdate}
                      onClick={updateCanalRecomendado}
                    >
                      Alterar {loadingUpdate && <Loading />}
                    </Button>
                    <Button variant="outlined" disabled={loadingDelete} onClick={deleteCanalRecomendado}>
                      Remover {loadingDelete && <Loading />}
                    </Button>
                  </>
                }
                {props.hasAdd && 
                  <Button variant="outlined" disabled={loadingAdd} onClick={addCanalRecomendado}>
                    Adicionar {loadingAdd && <Loading />}
                  </Button>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
