import * as React from "react";
import Box from "@mui/material/Box";
import {
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import { gql, useLazyQuery } from "@apollo/client";
import { toast, ToastContainer } from "react-toastify";
import Loading from "../components/Loading";
import Paper from "@mui/material/Paper";
import ButtonBase from "@mui/material/ButtonBase";
import { styled } from "@mui/material/styles";
import { useMutation } from "@apollo/client";


const GET_EXTERNAL_PLATFORMS = gql`
{
    externalPodcastsPlatforms {
      platform_id
      name
    }
}
`;

const GET_EPISODES = gql`
  query episodes($id: Int) {
    episodes(id: $id) {
        data{
            id
            feed_episode_id
            title
            description
            channel{
                name
            }
            thumbnail(width: 150, height: 150)
            external_platforms {
                platform_id
                url
            }
        }
    }
  }
`;

const ADD_EXTERNAL_PLATFORM = gql`
  mutation associateEpisodeExternalPlatform($episode_id: Int!, $platform_id: String!, $url: String!) {
    associateEpisodeExternalPlatform(episode_id: $episode_id, platform_id: $platform_id, url: $url)
  }
`;

const REMOVE_EXTERNAL_PLATFORM = gql`
  mutation removeEpisodeExternalPlatform($episode_id: Int!, $platform_id: String!) {
    removeEpisodeExternalPlatform(episode_id: $episode_id, platform_id: $platform_id)
  }
`;

const Img = styled("img")({
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  });
  
export default function LinksCompartilharEps() {
    const [epsSearch, setEpsSearch] = React.useState(0);
    const [externalPlatforms, setExternalPlatforms] = React.useState([]);
    const [deletedPlatformId, setDeletedPlatformId] = React.useState(null);

    const [getExternalPlatforms, { data }] = useLazyQuery(GET_EXTERNAL_PLATFORMS, {
        onError: (error) => {
          toast.error("ERROR: " + error.message);
        },
        fetchPolicy: "network-only",
       
      });
    
    const [getEps, { loading: loadingEps, data: dataEps }] = useLazyQuery(
        GET_EPISODES,
        {
          onError: (error) => {
            toast.error("ERROR: " + error.message);
          },
        }
    );

    const [addExternalPlatform, { loading: loadingAddPlatform }] = useMutation(
        ADD_EXTERNAL_PLATFORM,
        {
          onError: (error) => {
            toast.error("ERROR: " + error.message);
          },
          onCompleted: () => {
            toast.success("Platform saved successfully!");
            // Você pode adicionar lógica adicional aqui, como atualizar os dados ou recarregar a página
          },
        }
    );
    const [removeExternalPlatform, { loading: loadingRemovePlatform }] = useMutation(
        REMOVE_EXTERNAL_PLATFORM,
        {
          onError: (error) => {
            toast.error("ERROR: " + error.message);
          },
          onCompleted: () => {
            toast.success("Platform removed successfully!");
            // Você pode adicionar lógica adicional aqui, como atualizar os dados ou recarregar a página
          },
        }
    );
        
    const searchEps = (event) => {
        event.preventDefault();
    
        getEps({
          variables: {
            id: epsSearch,
          },
        });
    };

    const handleSavePlatform = (episodeId, platformId, url) => {
        addExternalPlatform({
          variables: {
            episode_id: episodeId,
            platform_id: platformId,
            url: url,
          },
        });
      };
      
      const handleRemovePlatform = async (episodeId, platformId) => {
        try {
          await removeExternalPlatform({
            variables: {
              episode_id: episodeId,
              platform_id: platformId,
            },
          });
      
          // Após a remoção bem-sucedida, atualizamos o estado apenas indicando a plataforma removida
          setDeletedPlatformId(platformId);
      
          toast.success("Platform removed successfully!");
        } catch (error) {
          toast.error("ERROR: " + error.message);
        }
      };
                                    
    const onChangeEps = (event) => {
        let id = parseInt(event.target.value)
        setEpsSearch(id);
    };
    const handlePlatformUrlChange = (e, platformId) => {
        const updatedPlatforms = externalPlatforms.map((platform) => {
          if (platform.platform_id === platformId) {
            return { ...platform, url: e.target.value };
          }
          return platform;
        });
      
        // Verificamos se a plataforma removida está sendo editada
        const deletedPlatform = updatedPlatforms.find((platform) => platform.platform_id === deletedPlatformId);
        if (deletedPlatform && deletedPlatform.url) {
          // Se a plataforma removida tiver uma URL definida, limpe a URL e redefina o estado
          deletedPlatform.url = '';
          setDeletedPlatformId(null);
        }
      
        setExternalPlatforms(updatedPlatforms);
      };
            
    React.useEffect(() => {
        getExternalPlatforms();
    }, [getExternalPlatforms]);

    React.useEffect(() => {
        if (data && data.externalPodcastsPlatforms) {
          const platformsWithUrls = data.externalPodcastsPlatforms.map((platform) => ({
            ...platform,
            url: '',
          }));
          setExternalPlatforms(platformsWithUrls);
        }
      }, [data]);

      React.useEffect(() => {
        if (dataEps && dataEps.episodes && dataEps.episodes.data && dataEps.episodes.data.length > 0) {
          const episode = dataEps.episodes.data[0];
          const platformsWithUrls = externalPlatforms.map((platform) => {
            const matchingPlatform = episode.external_platforms.find(
              (epPlatform) => epPlatform.platform_id === platform.platform_id
            );
            if (matchingPlatform) {
              return { ...platform, url: matchingPlatform.url };
            }
            return platform;
          });
          console.log("CHAMANDO")
          setExternalPlatforms(platformsWithUrls);
        }
      }, [dataEps]);
                  
      
      
  return (
        <Box
            onSubmit={searchEps}
            component="form"
            sx={{
                "& > :not(style)": { m: 1, width: "25" },
            }}
            noValidate
            autoComplete="off"
        >
        <Typography
            sx={{ mt: 4, mb: 2, fontSize: 18, fontWeight: "bolder" }}
            component="div"
        >
            Digite o ID do episódio:
        </Typography>

        <Grid container spacing={2}>
            <Grid item>
            <TextField
                onChange={onChangeEps}
                id="outlined-basic"
                label="Episódio"
                sx={{ width: 300 }}
                variant="outlined"
            />
            </Grid>
            <Grid item>
            <Button
                disabled={loadingEps}
                onClick={searchEps}
                variant="outlined"
                sx={{ marginTop: 1 }}
            >
                Buscar
                {loadingEps && <Loading />}
            </Button>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={6}>
          <Typography sx={{ mt: 4, mb: 5 }} variant="h6" component="div">
            Episodio:
          </Typography>
    
          {dataEps?.episodes?.data?.map((eps) => {
            return (
              <Paper
                key={eps.id}
                sx={{
                  p: 2,
                  marginBottom: 2,
                  maxWidth: "100%",
                  flexGrow: 1,
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                }}
              >
                <Grid container spacing={4}>
                  <Grid item>
                    <ButtonBase sx={{ width: 128, height: 128 }}>
                      <Img alt="complex" src={eps?.thumbnail} />
                    </ButtonBase>
                  </Grid>
                  <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                      <Grid item xs>
                        <Typography
                          gutterBottom
                          variant="subtitle1"
                          component="div"
                        >
                          {eps?.channel?.name}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {eps?.title}
                        </Typography>
                      </Grid>
                        {externalPlatforms.map((platform) => {
                            const episodeId = dataEps?.episodes?.data?.[0]?.id; // Obtemos o ID do episódio associado à plataforma
                            const isDeleted = platform.platform_id === deletedPlatformId;

                            return (
                                <Grid item key={platform.id}> 
                                    <TextField
                                        label={platform.name}
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => handlePlatformUrlChange(e, platform.platform_id)}
                                        value={isDeleted ? '' : platform.url}
                                        sx={{ width: "100%" }} // Aumenta o tamanho do campo de texto

                                    />
                                        <Button
                                            variant="contained"
                                            onClick={() => handleSavePlatform(episodeId, platform.platform_id, platform.url)}
                                            disabled={loadingAddPlatform}
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleRemovePlatform(episodeId, platform.platform_id)}
                                            disabled={loadingRemovePlatform}
                                        >
                                            Remove
                                        </Button>
                                </Grid>
                                
                            );
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            );
          })}
        </Grid>
        <ToastContainer/>
      </Grid>
    </Box> 
);
}
