import * as React from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { gql, useLazyQuery } from "@apollo/client";
import { toast, ToastContainer } from "react-toastify";
import Loading from "../components/Loading";
import { LinkedWebsite } from "../components/LinkedWebsite";

export const GET_LINKED_WEBSITE = gql`
  query linkedWebsite($codename: String) {
    linkedWebsite(codename: $codename) {
      id
      codename
      description
      channels_with_configs(page: 1, limit: 100) {
        data {
          order
          background_image(width: 200, height: 200)
          background_player_color
          title_color
          text_color
          title_size
          text_size
          progress_bar_color
          background_color
          channel {
            codename
            id
            name
            description
            thumbnail(width: 200, height: 200)
            cover(width: 400, height: 100)
            premium
            original
            main_category {
              id
            }
          }
        }
      }
    }
  }
`;

export const RESGATA_CHANNEL = gql`
  query channels($codename: String) {
    channels(codename: $codename, limit: 100) {
      data {
        codename
        id
        name
        description
        thumbnail(width: 200, height: 200)
        cover(width: 400, height: 100)
        premium
        original
        main_category {
          id
        }
      }
    }
  }
`;

const CODENAME = "braziljournal";

export default function BrazilJournal() {
  const [brJournal, setBrJournal] = React.useState("");
  const [dataSearch, setDataSearch] = React.useState("");

  const [getBr, { data: dataBrJournal }] =
    useLazyQuery(GET_LINKED_WEBSITE, {
      onError: (error) => {
        toast.error("ERROR: " + error.message);
      },
      fetchPolicy: "network-only",
    });

  const onChangeBrJournal = (event) => {
    setBrJournal(event.target.value);
  };
  const [searchChannel, { loading: loadingSearch }] =
    useLazyQuery(RESGATA_CHANNEL, {
      onError: (error) => {
        toast.error("ERROR: " + error.message);
      },
      onCompleted: (data) => {
        if (data?.channels?.data.length === 0) {
          toast.warning("Canal nao encontrado por codenome.");
        }
        setDataSearch(data);
      },
      fetchPolicy: "network-only",
    });

  const resgataBr = () => {
    if (brJournal !== '') {
      searchChannel({
        variables: {
          codename: brJournal,
        },
      });
    }
  };

  const onRemoved = () => {
    getBr({
      variables: {
        codename: CODENAME,
      },
    });
    toast.success("Canal removido com sucesso");
  };

  const onUpdate = () => {
    toast.success("Canal atualizado com sucesso");
  };

  const onAddChannel = () => {
    getBr({
      variables: {
        codename: CODENAME,
      },
    });
    setDataSearch(null);
    toast.success("Canal adicionado com sucesso");
  };

  React.useEffect(() => {
    getBr({
      variables: {
        codename: CODENAME,
      },
    });
  }, [getBr]);

  return (
    <>
      <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
        Digite o codenome do canal que deseja buscar:
      </Typography>

      <TextField
        id="outlined-basic"
        label="Codenome"
        variant="outlined"
        onChange={onChangeBrJournal}
      />
      <Button variant="outlined" sx={{ ml: 2, mt: 1 }} onClick={resgataBr}>
        Buscar {loadingSearch && <Loading />}
      </Button>

      {dataSearch?.channels?.data?.map((addChannel) => {
        return (
          <>
            <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
              Canal Adicionar:
            </Typography>
            <LinkedWebsite
              key={addChannel.id}
              channel={{ channel: addChannel }}
              linkedWebsiteId={dataBrJournal?.linkedWebsite?.id}
              onRemoved={onRemoved}
              onUpdate={onAddChannel}
              canRemove={false}
            />
          </>
        );
      })}

      <Grid item xs={12} md={6}>
        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
          Canais Brazil Journal:
        </Typography>
        {dataBrJournal?.linkedWebsite?.channels_with_configs?.data?.map(
          (channel) => {
            return (
              <LinkedWebsite
                key={channel?.channel?.id}
                channel={channel}
                linkedWebsiteId={dataBrJournal?.linkedWebsite?.id}
                onRemoved={onRemoved}
                onUpdate={onUpdate}
                canRemove={true}
              />
            );
          }
        )}
        <ToastContainer />
      </Grid>
    </>
  );
}
