import * as React from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { gql, useMutation } from "@apollo/client";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { useLazyQuery } from "@apollo/client";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import { ToastContainer, toast } from "react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";

const USER_EXPIRE_MUTATION = gql`
  mutation ChangeDtExpire($email: String!, $dt_expire: String!) {
    changeDtExpire(email: $email, dt_expire: $dt_expire)
  }
`;
const GET_USER_EMAIL = gql`
  query usersQuery($email: String!) {
    usersQuery(email: $email) {
      id
      email
      phone
      premium
      subscribed
      signature_expire_at
      celco
      email_verified_at
      msisdn_verified_at
      profile {
        name
        nickname
      }
    }
  }
`;

export default function Home() {
  const [dtExpire, setDtExpire] = React.useState(new Date());
  const [emailExpire, setEmailExpire] = React.useState();
  const [emailSearch, setEmailSearch] = React.useState();

  const handleDtExpireChange = (newValue) => {
    setDtExpire(newValue);
  };
  const onChangeEmailExpire = (event) => {
    setEmailExpire(event.target.value);
  };

  const searchUserEmail = (event) => {
    setEmailSearch(event.target.value);
  };

  const [changeDtExpire, { data, loading }] = useMutation(
    USER_EXPIRE_MUTATION,
    {
      onError: (error) => {
        toast.error("ERROR: " + error.message);
      },
      onCompleted: () => {
        toast.success("Salvo com sucesso !");
      },
      fetchPolicy: "network-only",
    }
  );

  const handleDtExpire = () => {
    changeDtExpire({
      variables: {
        email: emailExpire,
        dt_expire: format(dtExpire, "yyyy-MM-dd"),
      },
    });
  };
  const [getEmail, { loading: loadingEmail, data: dataEmail }] = useLazyQuery(
    GET_USER_EMAIL,
    {
      onError: (error) => {
        toast.error("ERROR: " + error.message);
      },
      fetchPolicy: "network-only",
    },
  );

  const searchUserByEmail = () => {
    getEmail({
      variables: {
        email: emailSearch,
      },
    });
  };

  const getInitials = (fullName) => {
    const [firstName, lastName] = fullName.split(" ");
    return `${firstName[0].toUpperCase()}${
      lastName ? lastName[0]?.toUpperCase() : ""
    }`;
  };

  if (data) {
    
  }

  return (
    <>
      <Grid container direction={"column"} spacing={5}>
        <Grid item xs={2}>
          <Typography style={{ fontSize: 16, fontWeight: "bolder" }}>
            Alterar data de expiracao do Usuario:
          </Typography>
          <TextField
            sx={{ width: 450 }}
            id="standard-search"
            label="Email do usuário"
            type="search"
            variant="standard"
            onChange={onChangeEmailExpire}
          />
        </Grid>
        <Grid item>
          <DesktopDatePicker
            label="Date expire"
            value={dtExpire}
            inputFormat="dd/MM/yyyy"
            mask="__/__/____"
            onChange={handleDtExpireChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item>
          <Button
            disabled={loading}
            onClick={handleDtExpire}
            variant="outlined"
            size="small"
          >
            Salvar
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Button>
          <ToastContainer />
        </Grid>
        <br />
        <Divider />
        <Grid item>
          <Typography style={{ fontSize: 16, fontWeight: "bolder" }}>
            Buscar Usuario via email:
          </Typography>
          <TextField
            sx={{ width: 450 }}
            id="standard-search"
            label="Email do usuário"
            type="search"
            variant="standard"
            onChange={searchUserEmail}
          />
        </Grid>
        <Grid item>
          <Box sx={{ position: "relative" }}>
            <Button
              variant="outlined"
              disabled={loadingEmail}
              onClick={searchUserByEmail}
              size="small"
            >
              Buscar
              {loadingEmail && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
          </Box>
        </Grid>
        {dataEmail && dataEmail.usersQuery && (
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              bgcolor: "background.paper",
              marginLeft: "49px",
              marginTop: "20px",
            }}
          >
            <ListItem alignItems="flex-start">
              <IconButton sx={{ p: 2 }}>
                <Avatar src="/static/images/avatar/2.jpg">
                  {getInitials(dataEmail.usersQuery.profile.name)}
                </Avatar>
              </IconButton>
              <ListItemText
                primary={dataEmail.usersQuery.profile.name}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      Id: {dataEmail.usersQuery.id}
                      <br />
                      Email: {dataEmail.usersQuery.email}
                      <br />
                      Celco: {dataEmail.usersQuery.celco}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        )}
      </Grid>
    </>
  );
}
