import * as React from "react";
import {
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import { gql, useLazyQuery } from "@apollo/client";
import { toast, ToastContainer } from "react-toastify";
import ChannelForm from "../components/ChannelForm";
import EpisodeForm from "../components/EpisodeForm";
import Loading from "../components/Loading";


const GET_CHANNEL_CODENAME = gql`
  query channels($codename: String!) {
    channels(codename: $codename) {
      data {
        id
        name
        codename
        description
        thumbnail(width: 200, height: 200)
        cover(width: 400, height: 100)
        premium
        original
        main_category {
          id
        }
        sponsorship {
          external_link
          image(width: 200, height: 100)
        }
        episodes(limit: 100) {
          data {
            id
            title
            description
            tags
            monetized
            restricted
            rating
            block_comments
            block_likes
            thumbnail(width: 200, height: 200)
            publishDate
            premium
            original
          }
        }
      }
    }
  }
`;

export default function NaoPremium() {
  const [canaisNaoPremium, setCanaisNaoPremium] = React.useState("");


  const [resgataChannel, { loading: loadingChannel, data: dataChannel }] =
    useLazyQuery(GET_CHANNEL_CODENAME, {
      onError: (error) => {
        toast.error("ERROR: " + error.message);
      },
      fetchPolicy: "no-caches",
    });

  const searchOriginais = (event) => {
    event.preventDefault();

    resgataChannel({
      variables: {
        codename: canaisNaoPremium,
      },
    });
  };

  const onChange = (event) => {
    setCanaisNaoPremium(event.target.value);
  };

  const onDeleteEpisode = () => {
    resgataChannel({
      variables: {
        codename: canaisNaoPremium,
      },
    });
  };

  return (
    <>
      <Typography style={{ fontSize: 16, fontWeight: "bolder" }}>
        Escolha o canal nao premium que deseja:
      </Typography>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-name-label"></InputLabel>
        <TextField
          onChange={onChange}
          id="outlined-basic"
          value={canaisNaoPremium}
          label="Canal"
          sx={{ width: 300 }}
          variant="outlined"
        />

      </FormControl>
      <Grid item>
        <Button
          disabled={loadingChannel}
          onClick={searchOriginais}
          variant="outlined"
          size="small"
        >
          Buscar
          {loadingChannel && <Loading />}
        </Button>
      </Grid>
      <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
        Canal:
      </Typography>
      {dataChannel?.channels?.data?.map((naoPremium) => {
        return (
          <div key={naoPremium.id}>
            <ChannelForm channel={naoPremium} />
            <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
              Episódios:
            </Typography>
            {naoPremium?.episodes?.data?.map((episode) => {
              return (
                <EpisodeForm
                  key={episode.id}
                  episode={episode}
                  onDelete={onDeleteEpisode}
                />
              );
            })}
          </div>
        );
      })}
      <ToastContainer />
    </>
  );
}
