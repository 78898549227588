import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { toast } from "react-toastify";
import React from "react";
import { styled } from "@mui/material/styles";

const UPDATE_CHANNEL_PREMIUM = gql`
  mutation editChannel(
    $channel_id: Int!
    $name: String
    $thumbnail: Upload
    $remove_sponsorship: Boolean
    $sponsorship_image: Upload
    $sponsorship_external_link: String
    $main_category_id: Int
    $cover: Upload
    $description: String
    $original: Boolean
    $premium: Boolean
  ) {
    editChannel(
      channel_id: $channel_id
      name: $name
      remove_sponsorship: $remove_sponsorship
      thumbnail: $thumbnail
      main_category_id: $main_category_id
      cover: $cover
      sponsorship_image: $sponsorship_image
      sponsorship_external_link: $sponsorship_external_link
      description: $description
      original: $original
      premium: $premium
    ) {
      id
    }
  }
`;

const RESGATA_CATEGORIAS = gql`
  query mainCategories {
    mainCategories {
      id
      name
    }
  }
`;

const Input = styled("input")({
  display: "none",
});

export default function ChannelForm(props) {
  const [name, setName] = React.useState(props?.channel?.name);
  const [link, setLink] = React.useState(
    props?.channel?.sponsorship_external_link
  );
  const [description, setDescription] = React.useState(
    props?.channel?.description
  );
  const [vertical, setVertical] = React.useState(
    props?.channel?.main_category?.id
  );
  const [original, setTypeOriginal] = React.useState(props?.channel?.original);
  const [premium, setTypePremium] = React.useState(props?.channel?.premium);
  const [cover, setCover] = React.useState(props?.channel?.cover);
  const [sponsorship, setSponsorship] = React.useState(
    props?.channel?.sponsorship?.image
  );
  const [thumbnail, setThumbnail] = React.useState(props?.channel?.thumbnail);
  const [coverUpload, setCoverUpload] = React.useState();
  const [sponsorshipUpload, setSponsorshipUpload] = React.useState();
  const [thumbnailUpload, setThumbnailUpload] = React.useState();
  

  const [updateChannel, { loading: loadingUpdate }] = useMutation(
    UPDATE_CHANNEL_PREMIUM,
    {
      onError: (error) => {
        let message = "";
       
        for (const validationError of error.graphQLErrors) {
          message += validationError.extensions.validation.description[0];
        }
        toast.error(message);
      },
      onCompleted: (data) => {
        toast.success("Canal Atualizado com Sucesso.");
      },
      fetchPolicy: "network-only",
    }
  );

  const { loading, data: categorias } = useQuery(RESGATA_CATEGORIAS, {
    onError: (error) => {
      toast.error("ERROR: " + error.message);
    },
    fetchPolicy: "network-only",
  });

  const onChangeName = (event) => {
    setName(event.target.value);
  };

  const onChangeLink = (event) => {
    setLink(event.target.value);
  };

  const onChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleVertical = (event) => {
    setVertical(event.target.value);
  };

  const handleTypeOriginal = (event) => {
    setTypeOriginal(event.target.checked);
  };

  const handleTypePremium = (event) => {
    setTypePremium(event.target.checked);
  };

  const onChangeThumbnailUpload = (event) => {
    setThumbnailUpload(event.target.files[0]);
    setThumbnail(URL.createObjectURL(event.target.files[0]));
  };

  const onChangeCoverUpload = (event) => {
    setCoverUpload(event.target.files[0]);
    setCover(URL.createObjectURL(event.target.files[0]));
  };

  const onChangeSponsorshipUpload = (event) => {
    setSponsorshipUpload(event.target.files[0]);
      setSponsorship(URL.createObjectURL(event.target.files[0]));
    
  };

  const onClickSponsorshipDelete = (event) => {
    setSponsorshipUpload(null);
    setSponsorship(null);

  }


  const handleSubmit = async (event) => {
    event.preventDefault();

    updateData();
  };

  const updateData = () => {
    updateChannel({
      variables: {
        channel_id: props?.channel?.id,
        name: name,
        thumbnail: thumbnailUpload,
        main_category_id: vertical,
        cover: coverUpload,
        description: description,
        original: original,
        premium: premium,
        remove_sponsorship: sponsorshipUpload === null? true : false,
        sponsorship_image: sponsorshipUpload,
        sponsorship_external_link: link,
      },
    });
  };

  return (
    <>
      <Card variant="outlined" sx={{ mb: "20px" }}>
      <Grid container>
        <Grid item xs={12} sx={{ ml:2, mt: 2 }}>
          <img alt="" className="iconDetails" src={cover} style={{ width: "400px", height: "100px" }}/>
        </Grid>
        
            <Typography
          style={{ fontSize: 18, fontWeight: "bolder", marginLeft: "8px" }}
          component="div"
        >
          Patrocínio:
        </Typography>
        <Grid item xs={12}  sx={{ ml:2, mt: 2 }}>
          {sponsorship &&
          <div className="img-wraps-ep">
          <img
            alt=""
            className="iconDetails"
            src={sponsorship}
            style={{ width: "400px", height: "100px" }}
          />
           <span class="closes" title="Delete" onClick={onClickSponsorshipDelete}  >×</span>
          </div>
       
          
          }
          
        </Grid>
        

        <Grid item sx={{ ml:2, mt: 2 }}>
          <img alt="" className="iconDetails" src={thumbnail} style={{ width: "200px", height: "200px" }}/>
          <div>
            <label htmlFor="thumbnail">
              <Input
                name="thumbnail"
                accept="image/*"
                id="thumbnail"
                type="file"
                onChange={onChangeThumbnailUpload}
                
              />
              <div>
                <Button
                  sx={{ display: "inline-flex" }}
                  variant="contained"
                  component="span"
                >
                  Upload Thumbnail
                </Button>
              </div>
              <div>{thumbnailUpload?.name}</div>
            </label>
            <label htmlFor="cover">
              <Input
                name="cover"
                accept="image/*"
                id="cover"
                type="file"
                onChange={onChangeCoverUpload}
              />

              <div>
                <Button
                  sx={{ display: "inline-flex", marginTop: 2 }}
                  variant="contained"
                  component="span"
                >
                  Upload cover
                </Button>
              </div>
            </label>
            <div>{coverUpload?.name}</div>
            <label htmlFor="sponsorship">
              <Input
                name="sponsorship"
                accept="image/*"
                id="sponsorship"
                type="file"
                onChange={onChangeSponsorshipUpload}
              />

              <div>
                <Button
                  sx={{ display: "inline-flex", marginTop: 2 }}
                  variant="contained"
                  component="span"
                >
                  Upload Patrocinio
                </Button>
              </div>
            </label>

            <div>{sponsorshipUpload?.name}</div>
          </div>
        </Grid>
        
        <Grid item sx={{ marginLeft: 2 }}>
           <Typography
            style={{ fontSize: 18, fontWeight: "bolder", marginTop: "4px" }}
            component="div"
          >
            Link Patrocinador:
          </Typography>
          <TextField
            fullWidth
            id="outlined-basic"
            label=""
            variant="outlined"
            onChange={onChangeLink}
            value={link}
            size="small"
            sx={{ width: 500 }}
          />
          
          <Typography
            style={{ fontSize: 18, fontWeight: "bolder", marginTop: "4px"}}
            component="div"
          >
            Nome:
          </Typography>
          <TextField
            fullWidth
            id="outlined-basic"
            label=""
            variant="outlined"
            onChange={onChangeName}
            value={name}
            size="small"
            sx={{ width: 500 }}
          />
          <Typography
            style={{ fontSize: 18, fontWeight: "bolder", marginTop: "4px" }}
            component="div"
          >
            Descricao:
          </Typography>
          <TextareaAutosize
            style={{ width: 500 }}
            aria-label="Descricao"
            placeholder="Descricao"
            value={description}
            onChange={onChangeDescription}
          />
          <Typography
            style={{ fontSize: 18, fontWeight: "bolder", marginBottom: 7, marginTop: "4px" }}
            component="div"
          >
            Vertical:
          </Typography>
          <FormControl sx={{ m: 0, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small">Vertical</InputLabel>
            <Select
              id="categorias"
              value={vertical}
              label="Classificacao"
              onChange={handleVertical}
              sx={{ width: 500 }}
              disabled={loading}
            >
              {categorias?.mainCategories?.map((categoria) => {
                return (
                  <MenuItem key={categoria.id} value={categoria.id}>
                    {categoria.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Typography
            style={{ fontSize: 18, fontWeight: "bolder", marginTop: "4px" }}
            component="div"
          >
            Tipo:
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={premium}
                  value={premium}
                  onChange={handleTypePremium}
                />
              }
              label="Premium"
            />
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={original}
                  value={original}
                  onChange={handleTypeOriginal}
                />
              }
              label="Original"
            />
          </FormGroup>
        </Grid>
      </Grid>
      <div className="container2">
        <Grid item>
          <Button
            disabled={loadingUpdate}
            onClick={handleSubmit}
            variant="outlined"
          
         
          >
            Atualizar
            {loadingUpdate && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Button>
        </Grid>
      </div>
      </Card>
    </>
  );
}
