import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { CircularProgress, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "@mui/material";
import Loading from "../components/Loading";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import BlockingBackdrop from "../components/BlockingBackdrop";
import { FeedChannel } from "../components/FeedChannel";

const IMPORT_CHANNEL = gql`
  mutation importChannelFromExistingFeed(
    $external_feed_id: String!
    $main_category_id: Int!
  ) {
    importChannelFromExistingFeed(
      external_feed_id: $external_feed_id
      main_category_id: $main_category_id
    ) {
      id
      name
      codename
      thumbnail(width: 200, height: 200)
    }
  }
`;

const FEED_CHANNELS = gql`
  query feedChannels($filter: String) {
    feedChannels(filter: $filter, page: 1, limit: 5) {
      data {
        id
        name
        codename
        thumbnail(width: 200, height: 200)
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
  }
`;

const RESGATA_CATEGORIAS = gql`
  query mainCategories {
    mainCategories {
      id
      name
    }
  }
`;

export default function ConverterFeed() {
  const [feedSearch, setFeedSearch] = React.useState();
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const [searchFeed, { loading: loadingSearch, data: dataSearch }] =
    useLazyQuery(FEED_CHANNELS, {
      onError: (error) => {
        toast.error("ERROR: " + error.message);
      },
      onCompleted: (data) => {
        if(!data.feedChannels) {
          toast.warning("Feed nao encontrado.");
        }
      },
      fetchPolicy: "network-only",
    });

  const { data: dataCategoria } = useQuery(RESGATA_CATEGORIAS, {
    onError: (error) => {
      toast.error("ERROR: " + error.message);
    },
    fetchPolicy: "network-only",
  });

  const [channelImport] = useMutation(IMPORT_CHANNEL, {
    onError: (error) => {
      toast.error("ERROR: " + error.message);
    },
    onCompleted: () => {
      setOpenBackdrop(false);
      toast.success("Feed convertido com sucesso.");
      searchFeed({
        variables: {
          filter: feedSearch,
        },
      });
    },
  });

  const searchFeedChannel = (event) => {
    event.preventDefault();

    searchFeed({
      variables: {
        filter: feedSearch,
      },
    });
  };

  const onChangeSearch = (event) => {
    setFeedSearch(event.target.value);
  };

  const onImportChannel = (feedId, categoryId) => {
    channelImport({
      variables: {
        external_feed_id: feedId,
        main_category_id: categoryId,
      },
    });
    setOpenBackdrop(true);
  };

  return (
    <Box
      onSubmit={searchFeedChannel}
      component="form"
      sx={{
        "& > :not(style)": { m: 1, width: "25" },
      }}
      noValidate
      autoComplete="off"
    >
      <Typography variant="h6">
        Converte Feed externo em Canal verificado OLA:
      </Typography>
      <Typography variant="h8">
        * A conversão pode demorar alguns minutos.
      </Typography>

      <Grid container spacing={2}>
        <Grid item>
          <TextField
            onChange={onChangeSearch}
            id="outlined-basic"
            label="Busque o feed externo por nome"
            variant="outlined"
            sx={{ width: 500 }}
          />
        </Grid>
        <Grid item>
          <Button
            disabled={loadingSearch}
            onClick={searchFeedChannel}
            variant="outlined"
            sx={{ mt: 1 }}
          >
            Buscar
            {loadingSearch && <Loading />}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={6}>
          {dataSearch?.feedChannels?.data.map((channel) => {
            return (
              <FeedChannel
                key={channel.id}
                categorias={dataCategoria?.mainCategories}
                channel={channel}
                onImportChannel={onImportChannel}
              />
            );
          })}
        </Grid>
      </Grid>
      <BlockingBackdrop open={openBackdrop}>
        <div>
          <Typography variant="h5">
            Isso pode demorar alguns minutos, por favor nao saia da pagina e nem
            atualize seu browser!
          </Typography>
        </div>
        <CircularProgress
          size={50}
          sx={{
            position: "absolute",
            top: "60%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      </BlockingBackdrop>
      <ToastContainer/>
    </Box>
  );
}
