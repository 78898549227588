import { Button, ButtonBase, Grid, MenuItem, OutlinedInput, Paper, Select, Typography } from "@mui/material";
import React from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function FeedChannel(props) {
  const [categoria, setCategoria] = React.useState('');
  const onChangeCategoria = (event) => {
    setCategoria(event.target.value);
  };
  const channel = props.channel;
  const importChannel = () => {
    props.onImportChannel(channel.id, categoria);
  };

  return (
    <Paper
      key={channel?.id}
      sx={{
        p: 2,
        marginBottom: 2,
        maxWidth: 500,
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === "dark" ? "#1A2027" : "#fff",
      }}
    >
      <Grid container spacing={2}>
        <Grid item>
          <ButtonBase sx={{ width: 128, height: 128 }}>
            <img
              alt="complex"
              style={{ width: "130px" }}
              src={channel?.thumbnail}
            />
          </ButtonBase>
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography gutterBottom variant="subtitle1" component="div">
                {channel?.name}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Categoria
              </Typography>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={categoria}
                onChange={onChangeCategoria}
                input={<OutlinedInput label="Name" />}
                MenuProps={MenuProps}
                size="small"
                sx={{ width: 200 }}
              >
                {props?.categorias.map((categoria) => (
                  <MenuItem key={categoria.id} value={categoria.id}>
                    {categoria.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={importChannel}>
                Converter
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
