export default function ProgressBar(props) {
    const progress = props.progress;
    return (
      <div className="progress-container" style={{ ...props.style }}>
        <div className="progressbar-container">
          <div className="progressbar-complete" style={{width: `${progress}%`}}>
            <div className="progressbar-liquid"></div>
          </div>
          <span className="progress">{progress}%</span>
        </div>
      </div>
    );
}