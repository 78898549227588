import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import logo from "../images/logoOla.svg";
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import AccountCircle from "@mui/icons-material/AccountCircle";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { gql, useMutation } from "@apollo/client";

const LOGIN_MUTATION = gql`
  mutation login($phone_or_email: String!, $password: String!, $contract: Int) {
    login(
      phone_or_email: $phone_or_email
      password: $password
      contract: $contract
    ) {
      id
      email
      phone
      access_token
      premium
      subscribed
      signature_expire_at
      celco
      email_verified_at
      msisdn_verified_at
      expires_in
      refresh_token
      profile {
        name
        nickname
        gender
        birth
        country_id
        state_id
        city_id
        profilePicture(width: 1, height: 1)
      }
      nestle
    }
  }
`;

const theme = createTheme();

export default function Login(props) {
  // let navigate = useNavigate();
  const [login, { data, loading, error }] = useMutation(LOGIN_MUTATION);

  // if (loading) return 'Submitting...';
  if (data) {
    console.log(data);
    localStorage.setItem("user", JSON.stringify(data));
    props.onSuccess(data);
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
   

    const data = new FormData(event.currentTarget);
    login({
      variables: {
        phone_or_email: data.get("email"),
        password: data.get("password"),
        contract: 1,
      },
    });
   
  };
  

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100vh",
            justifyContent: "center"
          }}
        >
          <img
            src={logo}
            className="App-logo"
            alt="Logo Ola Podcast"
            style={{ width: '200px', height: '70px' }}
          />
        
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, width: 400 }}
          >
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <AccountCircle sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                id="email"
                name="email"
                label="Email Address"
                variant="standard"
                autoComplete="email"
                required
                fullWidth
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <LockRoundedIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                id="password"
                name="password"
                label="Password"
                variant="standard"
                type="password"
                autoComplete="current-password"
                required
                fullWidth
              />
            </Box>
           
            <Button
            
              disabled={loading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>
            {error && error.message}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
