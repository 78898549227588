import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import logo from "../images/logoOla.svg";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";
import WebIcon from "@mui/icons-material/Web";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import Login from "./Login";
import Home from "./Home";
import Banners from "./Banners";
import Istoe from "./Istoe";
import Premium from "./Premium";
import NaoPremium from "./NaoPremium";
import Originais from "./Originais";
import ConverterFeed from "./ConverterFeed";
import BrazilJournal from "./BrazilJournal";
import EpsRecomendados from "./EpsRecomendados";
import CanaisRecomendados from "./CanaisRecomendados";
import CartaCapital from "./CartaCapital";
import LinksCompartilharEps from "./LinksCompartilharEps";



const drawerWidth = 240;

const Main = () => {
  const [user, setUser] = React.useState(null);
  const location = useLocation();

  const getInitials = (fullName = '') => {
    const [firstName = [''], lastName = ['']] = fullName.split(" ");
    if(!!firstName && !!firstName[0]){
      return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
    }else{
      return "";
    }
  };

  const onSuccess = (user) => {
    setUser(user);
  };

  let storedUser = localStorage.getItem("user");
  if (storedUser && user === null) {
    storedUser = JSON.parse(storedUser);
    setUser(storedUser);
  }

  if (!user) return <Login onSuccess={onSuccess} />;

  let cleanLocalStorage = () => {
    localStorage.removeItem("user");
    setUser(null);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        id="toolbarCss"
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Container
          maxWidth="xl"
          sx={{ display: "flex", marginLeft: "initial", alignItems: "center" }}
        >
          <Toolbar disableGutters>
            <img
              id="img-home"
              src={logo}
              className="App-logo"
              alt="Logo Ola Podcast"
              style={{ width: "200px", height: "70px", align: "left" }}
            />
          </Toolbar>
          <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "end" }}>
            <IconButton sx={{ p: 0 }}>
              <Avatar src="/static/images/avatar/2.jpg">
                {getInitials(user?.login?.profile?.name)}
              </Avatar>
            </IconButton>
          </Box>
        </Container>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            top: "auto",
          },
        }}
      >
        <Toolbar />
        <Box id="drawerCss" sx={{ overflow: "auto", height: "inherit" }}>
          <List>
            {[
              { text: "Home", link: "/home" },
              { text: "Premium", link: "/premium" },
              { text: "Nao Premium", link: "/naopremium" },
              { text: "Originais", link: "/originais" },
              { text: "Eps.Recomendados", link: "/epsrecomendados" },
              { text: "Compartilhamento Eps", link: "/linkscompartilhareps" },

            ].map((value, index) => (
              <Link to={value.link} key={value.text} className="menuLink">
                <ListItem disablePadding>
                  <ListItemButton
                    sx={{
                      backgroundColor:
                        location.pathname === value.link
                          ? "#eae5e5"
                          : "inherit",
                    }}
                  >
                    <ListItemIcon>
                      {index === 0 ? <HomeIcon /> : <WebIcon />}
                    </ListItemIcon>
                    <ListItemText>{value.text}</ListItemText>
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
          </List>
          <Divider />
          <List>
            {[
              { text: "Canais Recomendados", link: "/canaisrecomendados" },
              { text: "Converte Feed em Canal", link: "/converterfeed" },
              { text: "Banners", link: "/banners" },
              { text: "Banners Nestle", link: "/bannersnestle" },
              { text: "Brazil Journal", link: "/braziljournal" },
              { text: "Istoé", link: "/istoe" },
              { text: "Carta Capital", link: "/cartacapital" },

            ].map((textvalue, index) => (
              <Link
                key={textvalue.text}
                to={textvalue.link}
                className="menuLink"
              >
                <ListItem disablePadding>
                  <ListItemButton
                    sx={{
                      backgroundColor:
                        location.pathname === textvalue.link
                          ? "#eae5e5"
                          : "inherit",
                    }}
                  >
                    <ListItemIcon>
                      <WebIcon />
                    </ListItemIcon>
                    <ListItemText>{textvalue.text}</ListItemText>
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
          </List>
          <IconButton
            onClick={cleanLocalStorage}
            color="primary"
            aria-label="Logout-icon"
          >
            <LogoutIcon /> <p>Logout</p>
          </IconButton>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="banners" element={<Banners tipo="default" />} />
          <Route path="bannersNestle" element={<Banners tipo="nestle" />} />
          <Route path="istoe" element={<Istoe />} />
          <Route path="premium" element={<Premium />} />
          <Route path="naopremium" element={<NaoPremium />} />
          <Route path="originais" element={<Originais />} />
          <Route path="converterFeed" element={<ConverterFeed />} />
          <Route path="brazilJournal" element={<BrazilJournal />} />
          <Route path="epsRecomendados" element={<EpsRecomendados />} />
          <Route path="canaisRecomendados" element={<CanaisRecomendados />} />
          <Route path="cartaCapital" element={<CartaCapital />} />
          <Route path="linkscompartilhareps" element={<LinksCompartilharEps />} />
        </Routes>
      </Box>
    </Box>
  );
};
export default Main;
