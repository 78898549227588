import { Button, Grid, Paper } from "@mui/material";
import Loading from "./Loading";
import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import React from "react";

const REMOVE_BR_JOURNAL = gql`
  mutation removeChannelFromLinkedWebsite(
    $channel_id: String!
    $linked_website_id: String!
  ) {
    removeChannelFromLinkedWebsite(
      channel_id: $channel_id
      linked_website_id: $linked_website_id
    )
  }
`;

const ASSOCIATE_LINKED_WEBSITE_CHANNEL = gql`
  mutation associateLinkedWebsiteChannel(
    $channel_id: String!
    $linked_website_id: String!
    $background_player_color: String!
    $background_image_by_url: String
    $title_color: String!
    $text_color: String!
    $title_size: String!
    $text_size: String!
    $progress_bar_color: String!
    $background_color: String!
  ) {
    associateLinkedWebsiteChannel(
      channel_id: $channel_id
      linked_website_id: $linked_website_id
      background_player_color: $background_player_color
      title_color: $title_color
      text_color: $text_color
      title_size: $title_size
      text_size: $text_size
      progress_bar_color: $progress_bar_color
      background_color: $background_color
      background_image_by_url: $background_image_by_url
    )
  }
`;

export function LinkedWebsite(props) {
  const channel = props.channel;
  const [state, setState] = React.useState({
    background_color: channel?.background_color || "#FFFFFF",
    background_player_color: channel?.background_player_color || "#FFFFFF",
    title_color: channel?.title_color || "#FFFFFF",
    text_color: channel?.text_color || "#FFFFFF",
    progress_bar_color: channel?.progress_bar_color || "#FFFFFF",
    title_size: channel?.title_size || "",
    text_size: channel?.text_size || "",
  });
  const onChangeInput = (event) => {
    setState((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };
  const [removeChannel, { loading: loadingRemoveChannel }] = useMutation(
    REMOVE_BR_JOURNAL,
    {
      onError: (error) => {
        toast.error("ERROR: " + error.message);
      },
      onCompleted: (data) => {
        props.onRemoved();
      },
      fetchPolicy: "network-only",
    }
  );
  const [updateChannel, { loading: loadingUpdateChannel }] = useMutation(
    ASSOCIATE_LINKED_WEBSITE_CHANNEL,
    {
      onError: (error) => {
        toast.error("ERROR: " + error.message);
      },
      onCompleted: (data) => {
        props.onUpdate();
      },
      fetchPolicy: "network-only",
    }
  );

  const linkedWebsiteId = props.linkedWebsiteId;
  const onRemove = () => {
    removeChannel({
      variables: {
        channel_id: channel?.channel?.id,
        linked_website_id: linkedWebsiteId,
      },
    });
  };

  const handleSave = (event) => {
    updateChannel({
      variables: {
        channel_id: channel?.channel?.id,
        linked_website_id: linkedWebsiteId,
        background_player_color: state.background_player_color,
        // background_image_by_url: state.
        title_color: state.title_color,
        text_color: state.text_color,
        title_size: state.title_size,
        text_size: state.text_size,
        progress_bar_color: state.progress_bar_color,
        background_color: state.background_color,
      },
    });
  };

  return (
    <Paper
      // key={eps.id}
      sx={{
        p: 2,
        marginBottom: 2,
        maxWidth: 500,
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === "dark" ? "#1A2027" : "#fff",
      }}
    >
      <Grid container>
        <Grid item sx={{ marginRight: 1 }}>
          <img alt="" src={channel?.channel?.thumbnail} />
        </Grid>
        <Grid item>
          <div>
            <b>Nome: &nbsp;</b>
            {channel?.channel?.name}
          </div>
          <div>
            <b>Cor de fundo: &nbsp;</b>
            <input
              type="color"
              name="background_color"
              onChange={onChangeInput}
              value={state.background_color}
            />
          </div>
          <div>
            <b>Cor fundo do player: &nbsp;</b>
            <input
              type="color"
              name="background_player_color"
              onChange={onChangeInput}
              value={state.background_player_color}
            />
          </div>
          <div>
            <b>Cor do titulo: &nbsp;</b>
            <input
              type="color"
              name="title_color"
              onChange={onChangeInput}
              value={state.title_color}
            />
          </div>
          <div>
            <b>Cor do Texto: &nbsp;</b>{" "}
            <input
              type="color"
              name="text_color"
              onChange={onChangeInput}
              value={state.text_color}
            />
          </div>
          <div>
            <b>Cor da barra de progresso: &nbsp;</b>
            <input
              type="color"
              name="progress_bar_color"
              onChange={onChangeInput}
              value={state.progress_bar_color}
            />
          </div>
          <div>
            <b>Tamanho fonte titulo: &nbsp;</b>
            <input
              type="text"
              name="title_size"
              style={{ width: "40px" }}
              onChange={onChangeInput}
              value={state.title_size}
            />{" "}
            px
          </div>
          <div>
            <b>Tamanho fonte texto: &nbsp;</b>
            <input
              type="text"
              name="text_size"
              id="text_size"
              style={{ width: "40px" }}
              onChange={onChangeInput}
              value={state.text_size}
            />{" "}
            px
          </div>
        </Grid>
      </Grid>
      <Button
        sx={{ marginRight: 2 }}
        type="submit"
        disabled={loadingUpdateChannel}
        variant="outlined"
        size="small"
        onClick={handleSave}
      >
        Salvar
        {loadingUpdateChannel && <Loading />}
      </Button>
      {props.canRemove && (
        <Button
          sx={{ cursor: "pointer" }}
          variant="outlined"
          size="small"
          disabled={loadingRemoveChannel}
          onClick={onRemove}
        >
          Remover
          {loadingRemoveChannel && <Loading />}
        </Button>
      )}
    </Paper>
  );
}
