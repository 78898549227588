import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { toast, ToastContainer } from "react-toastify";
import ChannelForm from "../components/ChannelForm";
import EpisodeForm from "../components/EpisodeForm";
import Loading from "../components/Loading";

const GET_ORIGINAIS = gql`
  query channels {
    channels(original: true, limit: 100) {
      data {
        codename
        id
      }
    }
  }
`;

const RESGATA_EPS = gql`
  query channels($id: Int!) {
    channels(id: $id) {
      data {
        id
        name
        description
        thumbnail(width: 200, height: 200)
        cover(width: 400, height: 100)
        premium
        original
        main_category {
          id
        }
        sponsorship {
          external_link
          image(width: 200, height: 100)
        }
        episodes(limit: 100) {
          data {
            id
            title
            description
            tags
            monetized
            restricted
            rating
            block_comments
            block_likes
            thumbnail(width: 200, height: 200)
            publishDate
            premium
            original
          }
        }
      }
    }
  }
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Originais() {
  const [canaisOriginais, setCanaisOriginais] = React.useState("");

  const { data: dataOriginais } = useQuery(GET_ORIGINAIS, {
    onError: (error) => {
      toast.error("ERROR: " + error.message);
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const [resgataEps, { loading: loadingEps, data: dataEps }] = useLazyQuery(
    RESGATA_EPS,
    {
      onError: (error) => {
        toast.error("ERROR: " + error.message);
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    }
  );

  const searchOriginais = (event) => {
    event.preventDefault();

    resgataEps({
      variables: {
        id: canaisOriginais,
      },
    });
  };

  const onChangeOriginais = (event) => {
    setCanaisOriginais(event.target.value);
  };

  const onDeleteEpisode = () => {
    resgataEps({
      variables: {
        id: canaisOriginais,
      },
    });
  };

  return (
    <>
      <Typography style={{ fontSize: 16, fontWeight: "bolder" }}>
        Escolha o canal original que deseja:
      </Typography>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-name-label">Canais</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          value={canaisOriginais}
          onChange={onChangeOriginais}
          input={<OutlinedInput label="Name" />}
          MenuProps={MenuProps}
        >
          {dataOriginais?.channels?.data.map((name) => (
            <MenuItem key={name.id} value={name.id}>
              {name.codename}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Grid item>
        <Button
          disabled={loadingEps}
          onClick={searchOriginais}
          variant="outlined"
          size="small"
        >
          Buscar
          {loadingEps && <Loading />}
        </Button>
      </Grid>
      <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
        Canal:
      </Typography>
      {dataEps?.channels?.data?.map((original) => {
        return (
          <React.Fragment key={original.id}>
            <ChannelForm channel={original} />
            <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
              Episódios:
            </Typography>
            {original?.episodes?.data?.map((episode) => {
              return (
                <EpisodeForm
                  key={episode.id}
                  episode={episode}
                  onDelete={onDeleteEpisode}
                />
              );
            })}
          </React.Fragment>
        );
      })}
      <ToastContainer />
    </>
  );
}
