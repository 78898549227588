import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { gql, useLazyQuery } from "@apollo/client";
import { toast, ToastContainer } from "react-toastify";
import NewBanner from "../components/NewBanner";
import { Banner } from "../components/Banner";
import Loading from "../components/Loading";

const GET_BANNERS = gql`
  query slides(
    $banner_id: String
    $width: Int
    $height: Int
    $keywords: String
  ) {
    slides(banner_id: $banner_id, limit: 100, keywords: $keywords) {
      data {
        id
        title
        description
        action
        arguments
        hide_title
        hide_description
        visibility
        hint_position
        publishDate
        finalDate
        initialDate
        cover(width: $width, height: $height)
        keywords
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
  }
`;

export default function Banners(props) {
  const [showModal, setShowModal] = React.useState(false);

  const handleOpen = () => {
    setShowModal((prev) => !prev);
  };
  const [getHomeBanners, { loading, data: homeBanners }] =
    useLazyQuery(GET_BANNERS, {
      onError: (error) => {
        toast.error("ERROR: " + error.message);
      },
      variables: {
        banner_id:
          props.tipo === "default"
            ? "9b03af46-cff0-462e-9ca1-1994765c69a9"
            : "617b5eaf-9a61-4801-a297-64e6d1cc9aa0",
        width: 400,
        height: 100,
        keywords: null,
      },
      fetchPolicy: "network-only",
    });
  const [getWebPremium, { loading: loadingWebPremium, data: webPremium }] =
    useLazyQuery(GET_BANNERS, {
      onError: (error) => {
        toast.error("ERROR: " + error.message);
      },
      variables: {
        banner_id: "617b5eaf-9a61-4801-a297-64e6d1cc9aa0",
        width: 400,
        height: 100,
        keywords: null,
      },
      fetchPolicy: "network-only",
    });
  const [getAppPremium, { loading: loadingAppPremium, data: appPremium }] =
    useLazyQuery(GET_BANNERS, {
      onError: (error) => {
        toast.error("ERROR: " + error.message);
      },
      variables: {
        banner_id:
          props.tipo === "default"
            ? "27ee76b2-99cc-4e4b-b1ad-5ffd00182b87"
            : "27ee76b2-99cc-4e4b-b1ad-5ffd00182b87",
        width: 400,
        height: 100,
        keywords: null,
      },
      fetchPolicy: "network-only",
    });
  const [getAppHome, { loading: loadingAppHome, data: appHome }] = useLazyQuery(
    GET_BANNERS,
    {
      onError: (error) => {
        toast.error("ERROR: " + error.message);
      },
      variables: {
        banner_id: "8362c7a5-20aa-49f7-b8df-7f35a3410bb0",
        width: 400,
        height: 100,
        keywords: null,
      },
      fetchPolicy: "network-only",
    }
  );

  const onDelete = () => {
    toast.success("Slide removido com sucesso.");
    getHomeBanners();
    getWebPremium();
    getAppPremium();
    getAppHome();
  };

  const onCreate = () => {
    toast.success("Slide criado com sucesso.");
    getHomeBanners();
    getWebPremium();
    getAppPremium();
    getAppHome();
  };

  React.useEffect(() => {
    getHomeBanners();
    getWebPremium();
    getAppPremium();
    getAppHome();
  }, [getHomeBanners, getWebPremium, getAppHome, getAppPremium]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <div>
          <Button variant="outlined" onClick={handleOpen}>
            ADICIONAR BANNER
          </Button>
        </div>
        {showModal && <NewBanner onCreate={onCreate} onClose={handleOpen} />}
        <Grid item xs={12} md={6}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            {props.tipo === "default"
              ? "Banners Home Web"
              : "Banners Nestle Logado Web"}
            :
          </Typography>
          {loading && <Loading/>}
          {homeBanners?.slides?.data?.map((add) => {
            return <Banner key={add.id} slide={add} onDelete={onDelete} />;
          })}
        </Grid>
        {props.tipo === "default" && (
          <>
            <Grid item xs={12} md={6}>
              <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                Banners Logado Web:
              </Typography>
              {loadingWebPremium && <Loading/>}
              {webPremium?.slides?.data?.map((add) => {
                return <Banner key={add.id} slide={add} onDelete={onDelete} />;
              })}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                Banners Home App:
              </Typography>
              {loadingAppHome && <Loading/>}
              {appHome?.slides?.data?.map((add) => {
                return <Banner key={add.id} slide={add} onDelete={onDelete} />;
              })}
            </Grid>
          </>
        )}
        <Grid item xs={12} md={6}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            {props.tipo === "default"
              ? "Banners Logado App"
              : "Banners Nestle Logado App"}
            :
          </Typography>
          {loadingAppPremium && <Loading/>}
          {appPremium?.slides?.data?.map((add) => {
            return <Banner key={add.id} slide={add} onDelete={onDelete} />;
          })}
        </Grid>
      </Box>
      <ToastContainer />
    </Box>
  );
}
